import Head from "next/head"
import PrivateRoute from "../withPrivateRoute"
import { TopPage } from "../components"
import { t } from "i18next"

function Home() {
  return (
    <>
      <Head>
        <title> {t("Management screen top")}</title>
      </Head>
      <TopPage />
    </>
  )
}

export default PrivateRoute(Home)
