import React, { useContext, useEffect } from "react"
import Router from "next/router"
import { Layout as AntLayout, notification } from "antd"
import { AuthContext } from "./utils/AuthContext"
import { Footer, Header, Sidebar } from "./components"
import { Layout } from "./layout"
import Link from "next/link"
import { t } from "i18next"
import { UnderMaintenanceCard } from "@project/shared"

import { PageObj } from "./utils/PageAccessUtils"
import { hasPermissionForMenu } from "./utils/SidebarUtils"
import { InitialLoader } from "./components/molecules/InitialLoader/InitialLoader"
import styled from "styled-components"

const { Content } = AntLayout

const StyledMaintenanceCard = styled(UnderMaintenanceCard)`
  margin: 40px;
  @media (max-width: 767px) {
    margin: 20px;
  }
`

const PrivateRoute = (AuthComponent) => {
  function PrivateComponent({ children }) {
    const {
      authenticated,
      loading,
      user,
      permissions,
      isOwner,
      setOpenKeys,
      setActiveMenuKeys,
      collapsed,
      handleCollapse,
    } = useContext(AuthContext)
    useEffect(() => {
      const { pathname } = Router

      if (!loading) {
        if (!authenticated) {
          Router.push("/login")
          return
        }
        if (authenticated && pathname === "/") {
          if (user?.reloadUserInfo?.customAttributes) {
            const customAttributes = JSON.parse(
              user?.reloadUserInfo?.customAttributes
            )
            const companyId = customAttributes.companyId || 0
            localStorage.setItem("companyId", companyId)
          }
          Router.push("/")
        }

        if (pathname !== "/") {
          const currentPageObject = PageObj.find(
            (page) => page.routeLike == pathname
          )

          if (currentPageObject) {
            if (
              !isOwner &&
              !currentPageObject?.skipPermissions &&
              !hasPermissionForMenu(
                permissions,
                currentPageObject?.permissionKeys,
                currentPageObject?.premissionType
              )
            ) {
              notification.error({
                message: t("User doesn't have permission to view this page"),
              })
              Router.push("/")
            }

            setActiveMenuKeys(currentPageObject?.activeKeys)
          }
        } else {
          setOpenKeys([])
          setActiveMenuKeys([])
        }
      }
    }, [authenticated, loading, user])

    if (loading) {
      return <InitialLoader />
    }

    return <>{authenticated && <>{children(collapsed, handleCollapse)}</>}</>
  }
  type HigherState = {
    collapsed: boolean
    isModalOpen: boolean
  }
  return class Higher extends React.Component<
    Record<string, unknown>,
    HigherState
  > {
    constructor(props) {
      super(props)
      this.state = {
        collapsed:
          typeof window !== "undefined"
            ? localStorage.getItem("collapse_menu") === "true"
            : false,
        isModalOpen: false,
      }
    }

    handleCancel = () => {
      this.setState((state) => ({
        ...state,
        isModalOpen: false,
      }))
    }
    handleClickModal = () => {
      this.setState((state) => ({ ...state, isModalOpen: true }))
    }

    render() {
      return (
        <PrivateComponent>
          {(collapsed, handleCollapse) => (
            <React.Fragment>
              <Layout className={"dashboard"}>
                <Sidebar />
                <AntLayout className={"site-layout dashboard-site-content"}>
                  <Header />
                  <div className={"dashboard-mobile-sidebar-header"}>
                    <Link href={"/"}>
                      <a>
                        <div
                          className={
                            "dashboard-mobile-siderbar-header-logo-title"
                          }
                        >
                          <div className={"sidebar-header-logo"}>
                            <img src={"/assets/icons/easyylogonew.svg"} />
                          </div>
                          &nbsp;&nbsp;
                          {t("Growth Rehabilitation Support System")}
                        </div>
                      </a>
                    </Link>
                    <div>
                      {collapsed ? (
                        <img
                          src={"/assets/icons/MobileMenuTogglerIcon.svg"}
                          onClick={() => handleCollapse(!collapsed)}
                        />
                      ) : (
                        <div>
                          <img
                            src={"/assets/icons/CloseIcon.svg"}
                            onClick={() => handleCollapse(true)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <Content className={"dashboard-content"}>
                    {process.env.NEXT_PUBLIC_MAINTENANCE_MODE_FACILITY ===
                    "1" ? (
                      <StyledMaintenanceCard />
                    ) : (
                      <div className={"site-layout-background"}>
                        <AuthComponent {...this.props} />
                      </div>
                    )}
                  </Content>
                  <Footer />
                </AntLayout>
              </Layout>
            </React.Fragment>
          )}
        </PrivateComponent>
      )
    }
  }
}

export default PrivateRoute
