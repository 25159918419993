import { theme } from "@project/shared"
import { Layout as AntLayout } from "antd"
import styled from "styled-components"

export const Layout = styled(AntLayout)`
  &.dashboard {
    min-height: 100vh;
  }
  .sidebar-header-logo {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ffffff;
  }
  .dashboard-content {
    padding: 24px 0;
    /* ant table which reqires scrollbar to be always present*/
    @media not print {
      .always-present-scrollbar {
        /* show scrollbar all time*/
        .ant-table-content::-webkit-scrollbar {
          width: 8px;
        }

        .ant-table-content::-webkit-scrollbar-track {
          background: #fafafa;
        }

        .ant-table-content::-webkit-scrollbar-thumb {
          background-color: #c1c1c1;
          /* color of the scroll thumb */
          border-radius: 20px;
          /* roundness of the scroll thumb */
          border: 3px solid #fafafa;
          /* creates padding around scroll thumb */
        }
        /* show scrollbar all time*/

        /* scroll bars on pagination buttons */
        .pagination-class > div,
        .pagination-btns > div {
          /* show scrollbar all time*/
          ::-webkit-scrollbar {
            width: 8px;
          }

          ::-webkit-scrollbar-track {
            background: #fafafa;
          }

          ::-webkit-scrollbar-thumb {
            background-color: #c1c1c1;
            /* color of the scroll thumb */
            border-radius: 20px;
            /* roundness of the scroll thumb */
            border: 3px solid #fafafa;
            /* creates padding around scroll thumb */
          }
          /* show scrollbar all time*/
        }
      }
    }
  }
  .dashboard-mobile-sidebar-header {
    display: none;
  }
  @media (max-width: 768px) {
    .dashboard-content {
      padding: 10px;
    }
    .dashboard-mobile-sidebar-header {
      background-color: #fdd95f;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    }
    .dashboard-mobile-siderbar-header-logo-title {
      display: flex;
      align-items: center;
    }
  }
  @media print {
    .ant-layout.site-layout.dashboard-site-content {
      background-color: ${theme.base};
    }
    .dashboard-mobile-sidebar-header {
      display: none;
    }
    .dashboard-content {
      padding: 0px 0 !important;
    }
  }
`
